import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IBlockState } from "../../types";
import { ApiService, url } from "../../utils/endpoints";
import { toast } from "sonner";
import { getSimplifiedError } from "../../utils";
import { setStorageValue } from "../../utils/localStorage";

const block: IBlockState = {
  loading: false,
  success: false,
  error: {
    status: null,
    message: "",
  },
  data: null,
};

const initialState = {
  loading: false,
  success: false,
  error: { status: null, message: "" },
  user: {},
  token: "",
  isCheckingUsername: false,
  isUsernameAvailable: false,

  registerState: { ...block },
  verificationState: { ...block },
  accountSetupState: { ...block },
  passwordResetState: { ...block },
  resendOTPState: { ...block },
  googleState: { ...block },
  userImageState: { ...block },
  updateProfile: { ...block },
};

export const startLoading = (
  state: any,
  initialStateKey: string,
  payload: any
) => {
  if (initialStateKey === null) {
    state.loading = payload.loading;
  } else if (state.hasOwnProperty(initialStateKey)) {
    Object.keys(payload).forEach((key: string) => {
      if (state[initialStateKey].hasOwnProperty(key)) {
        state[initialStateKey][key] = payload[key];
      }
    });
  }
};

const handleFulfilled = (
  state: any,
  initialStateKey: string,
  { payload }: { payload: any }
) => {
  if (initialStateKey === null) {
    console.log("on getuser handlefulfilled", payload);
    state.token = payload.data.auth.token;
    state.user = payload.data.user;
    state.loading = false;
    state.success = true;
  } else if (initialStateKey === "checkusername") {
    state.isCheckingUsername = false;
    state.isUsernameAvailable = payload.data.available;
  } else if (initialStateKey === "registerState") {
    state.token = payload.data.auth.token;
    state.user = payload.data.user ?? {};
    state.loading = false;
    state.success = true;

    state[initialStateKey].loading = false;
    state[initialStateKey].success = true;
    state[initialStateKey].data = {
      ...payload.data,
    };
  } else if (initialStateKey === "accountSetupState") {
    state[initialStateKey].loading = false;
    state[initialStateKey].success = true;
    state[initialStateKey].data = {
      ...payload.data,
    };
    state.user = {
      ...state.user, // Keep existing user data
      ...payload.data, // Update with new user data
    };
  } else if (state.hasOwnProperty(initialStateKey)) {
    state[initialStateKey].loading = false;
    state[initialStateKey].success = true;
    state[initialStateKey].data = {
      ...payload.data,
    };
  }
};

export const handleRejected = (
  state: any,
  initialStateKey: string,
  action: PayloadAction<any>
) => {
  if (initialStateKey === null) {
    state.loading = false;
    state.success = false;
    state.error = {
      status: action.payload?.status || null,
      message: action.payload?.message || "",
    };
  } else if (state.hasOwnProperty(initialStateKey)) {
    state[initialStateKey].loading = false;
    state[initialStateKey].success = false;
    state[initialStateKey].error = {
      status: action.payload?.status || null,
      message: action.payload?.message || "",
    };
  }
};

export const onRegister = createAsyncThunk(
  "account/register",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.post(url.register, payload);
      toast.success("Account created successfully");
      return data;
    } catch (error: any) {
      return rejectWithValue(getSimplifiedError(error));
    }
  }
);

export const onSiginWithGoogle = createAsyncThunk(
  "account/google/signin",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.post(`${url.login}/firebase`, payload);
      toast.success("Login Successful");
      setStorageValue({ key: "provider", value: "google" });
      return data;
    } catch (error: any) {
      return rejectWithValue(getSimplifiedError(error));
    }
  }
);

export const onLogin = createAsyncThunk(
  "account/login",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.post(url.login, payload);
      toast.success("Login Successful");
      setStorageValue({
        key: "bmj_verified",
        value: data?.data?.user?.verified,
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(getSimplifiedError(error));
    }
  }
);

export const onVerifyEmail = createAsyncThunk(
  "account/verifyEmail",
  async (payload: any, { rejectWithValue, getState }) => {
    try {
      const user: any = getState();
      const { data } = await ApiService.post(url.verify, payload, {
        headers: {
          Authorization: `Bearer ${user?.user?.token}`,
        },
      });
      toast.success("Email Verification Successful");

      return data;
    } catch (error: any) {
      return rejectWithValue(getSimplifiedError(error));
    }
  }
);

export const onCheckUsernameAvailable = createAsyncThunk(
  "account/checkUsername",
  async (payload: any, { rejectWithValue, getState }) => {
    try {
      const user: any = getState();
      const { data } = await ApiService.get(
        `client/username/available?username=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${user?.user?.token}`,
          },
        }
      );
      toast.success(`Congratulations, ${payload} is available`);
      console.log({ data });
      return data;
    } catch (error: any) {
      return rejectWithValue(getSimplifiedError(error));
    }
  }
);

export const onAccountSetup = createAsyncThunk(
  "account/setup",
  async (payload: any, { rejectWithValue, getState }) => {
    try {
      const user: any = getState();
      const { data } = await ApiService.put(url.account_setup, payload, {
        headers: {
          Authorization: `Bearer ${user?.user?.token}`,
        },
      });
      toast.success("Account setup successful");
      return data;
    } catch (error: any) {
      return rejectWithValue(getSimplifiedError(error));
    }
  }
);

export const onRequestPasswordReset = createAsyncThunk(
  "account/passwordreset",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.post(`${url.reset}/request`, payload);
      toast.success("Password reset request successful");
      return data;
    } catch (error: any) {
      return rejectWithValue(getSimplifiedError(error));
    }
  }
);

export const onResendOTP = createAsyncThunk(
  "account/resendOTP",
  async (payload: any, { rejectWithValue, getState }) => {
    try {
      const user: any = getState();
      const { data } = await ApiService.post(url.resend, payload, {
        headers: {
          Authorization: `Bearer ${user?.user?.token}`,
        },
      });
      toast.success("OTP sent successfully to your email");
      setStorageValue({ key: "bmj_hash_key", value: data?.data?.hash });
      return data;
    } catch (error: any) {
      return rejectWithValue(getSimplifiedError(error));
    }
  }
);

export const onGetUser = createAsyncThunk(
  "account/getUser",
  async (payload: any, { rejectWithValue, getState }) => {
    try {
      const user: any = getState();
      console.log("user from the redux action", user.user.token);
      const { data } = await ApiService.get(url.profile, {
        headers: {
          Authorization: `Bearer ${user?.user?.token}`,
        },
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(getSimplifiedError(error));
    }
  }
);

export const onUpdateUserImage = createAsyncThunk(
  "account/profile_image",
  async (payload: any, { rejectWithValue, getState }) => {
    try {
      const user: any = getState();
      const { data } = await ApiService.put(`${url.profile}/image`, payload, {
        headers: {
          Authorization: `Bearer ${user?.user?.token}`,
        },
      });
      toast.success("Profile photo updated successfully");
      return data;
    } catch (error: any) {
      return rejectWithValue(getSimplifiedError(error));
    }
  }
);

export const onUpdateCreatorProfile = createAsyncThunk(
  "account/profileUpdate",
  async (payload: any, { rejectWithValue, getState }) => {
    try {
      const user: any = getState();
      const { data } = await ApiService.put(url.account_setup, payload, {
        headers: {
          Authorization: `Bearer ${user?.user?.token}`,
        },
      });
      toast.success("Profile updated successfully");
      return data;
    } catch (error: any) {
      return rejectWithValue(getSimplifiedError(error));
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearState: (state) => {
      Object.assign(state, initialState);
    },
    resetBlockAuth(state, action: PayloadAction<{ blockType: any }>) {
      return action.payload.blockType === null
        ? { ...state, loading: false, success: false }
        : {
            ...state,
            [action.payload.blockType]: {
              ...initialState[action.payload.blockType],
            },
          };
    },
    resetFlag(state, action: PayloadAction<{ blockType: string }>) {
      const { blockType } = action.payload;
      const initialStateForKey = initialState[blockType];
      if (blockType === null) {
        return {
          ...initialState,
          user: state.user, // Retain user data
        };
      }
      return {
        ...state,
        [blockType]: {
          ...state[blockType],
          loading: initialStateForKey.loading,
          success: initialStateForKey.success,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(onGetUser.pending, (state, action) => {
        startLoading(state, null, { loading: true, success: false });
      })
      .addCase(onGetUser.fulfilled, (state, action) => {
        handleFulfilled(state, null, action);
      })
      .addCase(onGetUser.rejected, (state, action) => {
        handleRejected(state, null, action);
      })
      .addCase(onLogin.pending, (state, action) =>
        startLoading(state, null, { loading: true, success: false })
      )
      .addCase(onLogin.fulfilled, (state, action) =>
        handleFulfilled(state, null, action)
      )
      .addCase(onLogin.rejected, (state, action) =>
        handleRejected(state, null, action)
      )
      .addCase(onRegister.pending, (state, action) =>
        startLoading(state, "registerState", { loading: true, success: false })
      )
      .addCase(onRegister.fulfilled, (state, action) =>
        handleFulfilled(state, "registerState", action)
      )
      .addCase(onRegister.rejected, (state, action) =>
        handleRejected(state, "registerState", action)
      )
      .addCase(onVerifyEmail.pending, (state, action) =>
        startLoading(state, "verificationState", {
          loading: true,
          success: false,
        })
      )
      .addCase(onVerifyEmail.fulfilled, (state, action) =>
        handleFulfilled(state, "verificationState", action)
      )
      .addCase(onVerifyEmail.rejected, (state, action) =>
        handleRejected(state, "verificationState", action)
      )
      .addCase(onRequestPasswordReset.pending, (state, action) => {
        startLoading(state, "passwordResetState", {
          loading: true,
          success: false,
        });
      })
      .addCase(onRequestPasswordReset.fulfilled, (state, action) => {
        handleFulfilled(state, "passwordResetState", action);
      })
      .addCase(onRequestPasswordReset.rejected, (state, action) => {
        handleRejected(state, "passwordResetState", action);
      })
      .addCase(onAccountSetup.pending, (state, action) =>
        startLoading(state, "accountSetupState", {
          loading: true,
          success: false,
        })
      )
      .addCase(onAccountSetup.fulfilled, (state, action) =>
        handleFulfilled(state, "accountSetupState", action)
      )
      .addCase(onAccountSetup.rejected, (state, action) =>
        handleRejected(state, "accountSetupState", action)
      )
      .addCase(onCheckUsernameAvailable.pending, (state, action) =>
        startLoading(state, "checkusername", { loading: true, success: false })
      )
      .addCase(onCheckUsernameAvailable.fulfilled, (state, action) =>
        handleFulfilled(state, "checkusername", action)
      )
      .addCase(onCheckUsernameAvailable.rejected, (state, action) =>
        handleRejected(state, "checkusername", action)
      )
      .addCase(onResendOTP.pending, (state, action) =>
        startLoading(state, "resendOTPState", {
          loading: true,
          success: false,
        })
      )
      .addCase(onResendOTP.fulfilled, (state, action) =>
        handleFulfilled(state, "resendOTPState", action)
      )
      .addCase(onResendOTP.rejected, (state, action) =>
        handleRejected(state, "resendOTPState", action)
      )
      .addCase(onSiginWithGoogle.pending, (state, action) => {
        startLoading(state, null, {
          loading: true,
          success: false,
        });
      })
      .addCase(onSiginWithGoogle.fulfilled, (state, action) => {
        handleFulfilled(state, null, action);
      })
      .addCase(onSiginWithGoogle.rejected, (state, action) => {
        handleRejected(state, null, action);
      })
      .addCase(onUpdateUserImage.pending, (state, action) => {
        startLoading(state, "userImageState", {
          loading: true,
          success: false,
        });
      })
      .addCase(onUpdateUserImage.fulfilled, (state, action) => {
        handleFulfilled(state, "userImageState", action);
      })
      .addCase(onUpdateUserImage.rejected, (state, action) => {
        handleRejected(state, "userImageState", action);
      })
      .addCase(onUpdateCreatorProfile.pending, (state, action) => {
        startLoading(state, "updateProfile", { loading: true, success: false });
      })
      .addCase(onUpdateCreatorProfile.fulfilled, (state, action) => {
        handleFulfilled(state, "updateProfile", action);
      })
      .addCase(onUpdateCreatorProfile.rejected, (state, action) => {
        handleRejected(state, "updateProfile", action);
      });
  },
});

export const authSelector = (state: any) => state.user;
export const { clearState, resetBlockAuth, resetFlag } = authSlice.actions;

export default authSlice.reducer;
