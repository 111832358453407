import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  clearStateFn,
  resetBlockFn,
  handleFulfilled,
  initialStateObj,
} from "../../utils/common";
import { ApiService, url } from "../../utils/endpoints";
import { getSimplifiedError } from "../../utils";
import { handleRejected, startLoading } from "../Authentication/authSlice";
import { toast } from "sonner";

const initialState = {
  ...initialStateObj,
  createMembershipState: { ...initialStateObj },
  deleteMemebershipState: { ...initialStateObj },
  editMembershipState: { ...initialStateObj },
};

console.log(initialState);

export const postMembership = createAsyncThunk(
  "createMembership",
  async (payload: any, { rejectWithValue, getState }) => {
    try {
      const user: any = getState();
      const { data } = await ApiService.post(url.membership, payload, {
        headers: { Authorization: `Bearer ${user?.user?.token}` },
      });
      console.log({ "create mmebership data": data });
      toast.success(`${payload?.name} membership created successsfully`);
      return data;
    } catch (error: any) {
      return rejectWithValue(getSimplifiedError(error));
    }
  }
);

export const deleteMembership = createAsyncThunk(
  "deleteMembership",
  async (payload: string, { rejectWithValue, getState }) => {
    try {
      const user: any = getState();
      const { data } = await ApiService.delete(`${url.membership}/${payload}`, {
        headers: { Authorization: `Bearer ${user?.user?.token}` },
      });
      console.log({ deleteData: data });
      toast.success("Membership deleted successfully!");
      return data;
    } catch (error: any) {
      return rejectWithValue(getSimplifiedError(error));
    }
  }
);

export const editMembership = createAsyncThunk(
  "editMembership",
  async (payload: any, { rejectWithValue, getState }) => {
    try {
      const id = payload.id;
      delete payload.id;
      const user: any = getState();
      const { data } = await ApiService.put(`${url.membership}/${id}`, {
        headers: { Authorization: `Bearer ${user?.user?.token}` },
      });
      toast.success("Membership edited successfully");
      return data;
    } catch (error: any) {
      return rejectWithValue(getSimplifiedError(error));
    }
  }
);

export const membershipSlice = createSlice({
  name: "membership",
  initialState,
  reducers: {
    clearState: (state) => {
      return clearStateFn(state, initialState);
    },
    resetMembershipBlock: (state, action) => {
      console.log("i am here trying to reset");
      return resetBlockFn(state, action, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postMembership.pending, (state, action) => {
        startLoading(state, "createMembershipState", {
          loading: true,
          success: false,
        });
      })
      .addCase(postMembership.fulfilled, (state, action) => {
        handleFulfilled(state, "createMembershipState", action);
      })
      .addCase(postMembership.rejected, (state, action) => {
        handleRejected(state, "createMembershipState", action);
      })
      .addCase(deleteMembership.pending, (state, action) => {
        startLoading(state, "deleteMemebershipState", {
          loading: true,
          success: false,
        });
      })
      .addCase(deleteMembership.fulfilled, (state, action) => {
        handleFulfilled(state, "deleteMemebershipState", action);
      })
      .addCase(editMembership?.pending, (state, action) => {
        startLoading(state, "editMembershipState", {
          loading: true,
          success: false,
        });
      })
      .addCase(editMembership?.fulfilled, (state, action) => {
        handleFulfilled(state, "editMembershipState", action);
      })
      .addCase(editMembership.rejected, (state, action) => {
        handleRejected(state, "editMembershipState", action);
      });
  },
});

export const membershipSelector = (state: any) => state.membership;
export const { clearState, resetMembershipBlock } = membershipSlice.actions;

export default membershipSlice.reducer;
