export const supportTrafficData = [
  {
    name: "Week 1",
    uv: 4000,
    pv: 27000,
    amt: 2400,
  },
  {
    name: "Week 2",
    uv: 3000,
    pv: 15000,
    amt: 2210,
  },
  {
    name: "Week 3",
    uv: 2000,
    pv: 23500,
    amt: 2290,
  },
  {
    name: "Week 4",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Week 5",
    uv: 1890,
    pv: 36540,
    amt: 2181,
  },
];

export const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export const memebershipData = [
  {
    name: "super fans",
    amount: 1000,
    description:
      "The best option for fans who are interested in some level of privacy ",
    rewards: [
      {
        id: 1,
        reward: "25% discount to all my premium products",
      },
      {
        id: 2,
        reward: "Get at least 3 merch sent to your every month",
      },
      {
        id: 3,
        reward: "Exclusive access to all my premium tutorials",
      },
      {
        id: 4,
        reward: "1:1 video conversation, just book my calendar",
      },
      {
        id: 5,
        reward: "1 Personalised request every month",
      },
    ],
  },
  {
    name: "super duper fans",
    amount: 10000,
    description:
      "The best option for fans who are interested in some level of privacy ",
    rewards: [
      {
        id: 1,
        reward: "25% discount to all my premium products",
      },
      {
        id: 2,
        reward: "Get at least 3 merch sent to your every month",
      },
      {
        id: 3,
        reward: "Exclusive access to all my premium tutorials",
      },
      {
        id: 4,
        reward: "1:1 video conversation, just book my calendar",
      },
      {
        id: 5,
        reward: "1 Personalised request every month",
      },
    ],
  },
];


export const wishlistCategory = [
  {
    id: "1",
    name: "birthday",
    value: "birthday",
    label: "Birthday",
  },
  {
    id: "2",
    name: "engagement",
    value: "engagement",
    label: "Engagement",
  },
  {
    id: "3",
    name: "charity",
    value: "charity",
    label: "Charity",
  },
  {
    id: "4",
    name: "new business",
    value: "new business",
    label: "New Business",
  },
  {
    id: "5",
    name: "wedding",
    value: "wedding",
    label: "Wedding",
  },
  {
    id: "6",
    name: "graduation",
    value: "graduation",
    label: "Graduation",
  },
  {
    id: "7",
    name: "anniversary",
    value: "anniversary",
    label: "Anniversary",
  },
  {
    id: "8",
    name: "christmas",
    value: "christmas",
    label: "Christmas",
  },
  {
    id: "9",
    name: "new home",
    value: "new home",
    label: "New Home",
  },
  {
    id: "10",
    name: "personal",
    value: "personal",
    label: "Personal",
  },
  {
    id: "11",
    name: "valentine",
    value: "valentine",
    label: "Valentine",
  },
  {
    id: "12",
    name: "secret santa",
    value: "secret santa",
    label: "Secret Santa",
  },
  {
    id: "13",
    name: "social cause",
    value: "social cause",
    label: "Social Cause",
  },
  {
    id: "14",
    name: "baby shower",
    value: "baby shower",
    label: "Baby Shower",
  },
  {
    id: "15",
    name: "other",
    value: "other",
    label: "Other",
  },
];
export const people = [
  {
    id: 1,
    name: "Wade Cooper",
    value: "Wade Cooper",
    label: "WADE COOPER",
  },
  {
    id: 2,
    name: "Arlene Mccoy",
    value: "Arlene Mccoy",
    label: "ARLENE MCCOY",
  },
  {
    id: 3,
    name: "Devon Webb",
    value: "Devon Webb",
    label: "DEVON WEBB",
  },
  {
    id: 4,
    name: "Tom Cook",
    value: "Tom Cook",
    label: "TOM COOK",
  },
  {
    id: 5,
    name: "Tanya Fox",
    value: "Tanya Fox",
    label: "TANYA FOX",
  },
  {
    id: 6,
    name: "Hellen Schmidt",
    value: "Hellen Schmidt",
    label: "HELLEN SCHMIDT",
  },
  {
    id: 7,
    name: "Caroline Schultz",
    value: "Caroline Schultz",
    label: "CAROLINE SCHULTZ",
  },
  {
    id: 8,
    name: "Mason Heaney",
    value: "Mason Heaney",
    label: "MASON HEANEY",
  },
  {
    id: 9,
    name: "Claudie Smitham",
    value: "Claudie Smitham",
    label: "CLAUDIE SMITHAM",
  },
  {
    id: 10,
    name: "Emil Schaefer",
    value: "Emil Schaefer",
    label: "EMIL SCHAEFER",
  },
];



export const socialMediaPlatforms = [
  {
    name: 'Instagram',
    value: 'instagram',
    label: "Instagram",
  },
  {
    name: 'Twitter',
    value: 'twitter',
    label: "Twitter",
  },
  {
    name: 'Facebook',
    value: 'facebook',
    label: "Facebook",
  },
  {
    name: 'Tiktok',
    value: 'tiktok',
    label: "Tiktok",
  },
  {
    name: 'Youtube',
    value: 'youtube',
    label: "Youtube",
  },
  {
    name: 'Snapchat',
    value: 'snapchat',
    label: "Snapchat",
  },
]