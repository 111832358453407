import Loader from "../Loader";
import { VariantProps, cva } from "class-variance-authority";

import googleIcon from "../../assets/images/google-icon.png";

const button = cva(
  [
    "flex items-center justify-center rounded-full font-gt-w-medium text-base",
    "transition outline-none disabled:cursor-not-allowed",
  ],
  {
    variants: {
      intent: {
        primary: ["text-primary-600 bg-black"],
        secondary: [
          "bg-white text-neutral-600 border-[1px] border-neutral-100",
        ],
        green: ["bg-[#28B446]"],
        danger: ["bg-error600 text-white"],
      },
      size: {
        medium: ['py-3 px-10'],
        large: ["py-4 px-4"],
        small: ["w-fit text-xs h-[40px] px-4"]
      },
    },
    compoundVariants: [{ intent: "primary", size: "large" }],
    defaultVariants: {
      intent: "primary",
      size: "large",
    },
  }
);

type props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  isLoading?: boolean;
  fullWidth?: boolean;
  hasImage?: boolean;
  withIcon?: any;
};

export interface ButtonProps extends props, VariantProps<typeof button> {}

/**
 * @param {String} intent: any of ["primary", "secondary", "danger"]
 * @param {String} size: any of ["small", "medium", "large"]
 * @param {boolean} isLoading: if true, show a loader
 * @returns
 */
export const AppButton: React.FC<ButtonProps> = ({
  intent,
  size,
  isLoading,
  fullWidth,
  hasImage,
  disabled,
  withIcon,
  ...props
}) => (
  <button
    className={`${button({ intent, size })} ${fullWidth ? "w-full" : ""}`}
    {...props}
    disabled={isLoading || disabled}
  >
    {isLoading ? (
      <Loader color={intent === 'primary' ? "#fff" : "#000"}/>
    ) : (
      <>
        {hasImage && <img src={googleIcon} alt="google-icon" className="w-4 mr-2"/> } {props.title}

        {withIcon}
      </>
    )}
  </button>
);

export default AppButton;
